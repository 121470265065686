import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import axiosInstance from '@/axios'
import { type AxiosResponse, isAxiosError } from 'axios'
import { type ValidationMessage } from '@/utils/i18n'
import { useAuthStore } from './auth'
import { useAlertStore } from './alerts'
import { useI18n } from 'vue-i18n'

type LanguageOptions = 'en' | 'fi'


export const formatString = (msg: ValidationMessage, fieldname: string): string => {
  const { t } = useI18n()
  if (typeof msg == 'object') {
    return t(msg.key, { ...msg.values, ...{ _field_: t(fieldname) } })
  } else {
    return msg
  }
}

interface LanguageStore {
  selectedLanguage: Ref<LanguageOptions>
  loadedLangGeneral: Ref<boolean>
  loadedLangForMe: Ref<boolean>
  showLoading: Ref<boolean>
  countingDuration: Ref<number>
  $reset: () => void
  setSelectedLanguage: (lang: 'en' | 'fi') => void
  formatString: (msg: ValidationMessage, fieldname: string) => string
  retrieveLanguages: () => Promise<any>
  handleLoadingLang: (lang: 'en' | 'fi') => Promise<void>
  retrieveGeneralLanguage: (lang: string) => Promise<any>
  retrieveLanguageForMe: (lang: string) => Promise<any>
  retrieveSharedLang: (lang: string, shareCode: string) => Promise<any>
  retrieveLanguageMessagesByCategory: (langData: { lang: string; category: string }) => Promise<any>
  saveLanguageMessages: (messagesData: any) => Promise<any>
  resetLanguageMessages: (messagesData: any) => Promise<any>
}

interface RetrieveLanguagesResponse {
  country_code: string
  id: number
  language_code: string
  name: string
  name_in_native_language: string
}

// NOTE: These are all correctly migrated
// TODO: Implement better error handling
export const useLanguageStore = defineStore(
  'language',
  (): LanguageStore => {
    const i18n = useI18n()

    // State
    const selectedLanguage = ref<LanguageOptions>('en')
    const loadedLangGeneral = ref(false)
    const loadedLangForMe = ref(false)
    const showLoading = ref(true)
    const countingDuration = ref(500)

    // https://pinia.vuejs.org/core-concepts/state.html#Resetting-the-state
    const $reset = () => {
      loadedLangGeneral.value = false
      loadedLangForMe.value = false
      showLoading.value = false
      countingDuration.value = 500
    }

    // Actions
    const setSelectedLanguage = (lang: 'en' | 'fi'): void => {
      selectedLanguage.value = lang
      i18n.locale.value = lang
      axiosInstance.defaults.headers.common['Accept-Language'] = lang
    }

    // TODO: but this in a better place
    // This now formats the string using I18n, or just returns the native Yup string

    const retrieveLanguages = async (): Promise<AxiosResponse<RetrieveLanguagesResponse[]>> => {
      const alertStore = useAlertStore()
      try {
        return await axiosInstance.get('/api/languages/get/all')
      } catch (error) {
        if (isAxiosError(error)) {
          alertStore.handleError(error)
        }
        return Promise.reject(error)
      }
    }

    const handleLoadingLang = async (lang: 'fi' | 'en') => {
      const authStore = useAuthStore()
      const alertStore = useAlertStore()

      const generalResp = retrieveGeneralLanguage(lang)

      let forMeResp = new Promise((resolve) => { resolve(null) })
      if (authStore.loggedIn) {
        forMeResp = retrieveLanguageForMe(lang)
      }

      try {
        await generalResp
      }
      catch (error) {
        alertStore.handleError(error)
      }
      try {
        await forMeResp
      }
      catch (error) {
        alertStore.handleError(error)
      }

      const timeout = new Promise((resolve) => setTimeout(resolve, countingDuration.value))
      await timeout;
      setSelectedLanguage(lang)
    }


    const retrieveGeneralLanguage = async (lang: string) => {
      loadedLangGeneral.value = false
      try {
        const response = await axiosInstance.get(`/api/languages/get/${lang}/general`)
        const messages = response.data

        // TODO: This can be removed after updating the string in the prod DB
        if (messages.client_login_text?.email_placeholder !== undefined) {
          delete messages.client_login_text.email_placeholder
        }

        i18n.mergeLocaleMessage(lang, messages)
      } catch (error) {
        throw error
      } finally {
        loadedLangGeneral.value = true
      }
    }


    const retrieveLanguageForMe = async (lang: string) => {
      loadedLangForMe.value = false
      try {
        const response = await axiosInstance.get(`/api/languages/get/${lang}/for-me`)
        const messages = response.data
        i18n.mergeLocaleMessage(lang, messages)
      } catch (error) {
        throw error
      } finally {
        loadedLangForMe.value = true
      }
    }

    const retrieveSharedLang = async (lang: string, shareCode: string) => {
      try {
        const response = await axiosInstance.get(`/api/shared/get/language/${shareCode}/${lang}`)
        const messages = response.data
        i18n.mergeLocaleMessage(lang, messages)
        loadedLangForMe.value = true
      } catch (error) {
        throw error
      }
    }

    const retrieveLanguageMessagesByCategory = async (langData: {
      lang: string
      category: string
    }) => {
      const { lang, category } = langData
      try {
        const response = await axiosInstance.get(`/api/languages/get/${category}/${lang}`)
        return response.data
      } catch (error) {
        throw error
      }
    }

    const saveLanguageMessages = async (messagesData: any) => {
      try {
        return await axiosInstance.post('/api/languages/post/messages', messagesData)
      } catch (error) {
        throw error
      }
    }

    const resetLanguageMessages = async (messagesData: any) => {
      try {
        return await axiosInstance.post('/api/languages/reset/messages', messagesData)
      } catch (error) {
        throw error
      }
    }

    return {
      $reset,
      selectedLanguage,
      loadedLangGeneral,
      loadedLangForMe,
      showLoading,
      countingDuration,
      setSelectedLanguage,
      formatString,
      retrieveLanguages,
      handleLoadingLang,
      retrieveGeneralLanguage,
      retrieveLanguageForMe,
      retrieveSharedLang,
      retrieveLanguageMessagesByCategory,
      saveLanguageMessages,
      resetLanguageMessages
    }
  },
  { persist: true }
)
