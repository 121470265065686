<template>
  <UpsellModal :userData="userData" v-if="userData != null" v-model="showUpsellModal" />

  <nav class="border-gray-200 bg-gp-blue">
    <div class="relative mx-auto flex flex-wrap items-center justify-between p-4">
      <router-link to="/" class="ml-6 flex items-center">
        <img :src="proGrowthLogo" class="mr-3 h-12" alt="Growth Plan logo" />
      </router-link>
      <button @click="showMenu = !showMenu" type="button"
        class="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 md:hidden"
        aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15" />
        </svg>
      </button>
      <div class="left-0 top-full z-50 hidden w-full bg-gray-50 md:static md:block md:w-auto md:bg-transparent"
        id="navbar-default">
        <ul
          class="mt-4 flex flex-col rounded-lg border border-gray-100 p-4 font-medium text-white md:mt-0 md:flex-row md:space-x-8 md:border-0 md:p-0">
          <li v-if="loggedIn && role == 'User'">
            <router-link to="/client/dashboard" data-test="nav-user-dashboard">{{
              t('navbar_text.dashboard')
            }}</router-link>
          </li>
          <li v-if="loggedIn && role == 'Consultant'">
            <router-link to="/consultant/dashboard">{{ t('navbar_text.dashboard') }}</router-link>
          </li>
          <li v-if="loggedIn && role == 'Admin'">
            <router-link to="/admin/dashboard">{{ t('navbar_text.dashboard') }}</router-link>
          </li>
          <li v-if="loggedIn && !userActiveGrowthplan && role == 'User'" data-test="growthplan-start-nav">
            <button id="start-growthplan" @click="onStartGrowthplan">
              {{ t('navbar_text.start_growthplan') }}
            </button>
          </li>
          <li v-if="loggedIn && userActiveGrowthplan && role == 'User'">
            <button id="my-growthplan" @click="onNavigateToGrowthplan" data-test="growthplan-continue-nav">
              {{ t('navbar_text.my_growthplan') }}
            </button>
          </li>
          <li v-if="loggedIn && userActiveGrowthplan && role == 'User'">
            <button id="all-todo-navbar" @click="onNavigateToAlltodo" data-test="growthplan-all-todo-nav">
              {{ t('general_dashboard_text.all_todo') }}
            </button>
          </li>
          <li v-if="loggedIn">
            <button @click="handleLogout" data-test="logout">{{ t('navbar_text.logout') }}</button>
          </li>
          <li class="flex align-middle">
            <LocaleSwitcher />
          </li>

        </ul>
      </div>
      <div :class="{
        hidden: !showMenu,
        'absolute left-0 top-full z-50 flex w-full justify-center ': true
      }" id="navbar-default">
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useClientStore, type UserAccount } from '@/stores/client'
import UpsellModal from '@/components/dashboard/client/UpsellModal.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'

// Image imports
import proGrowthLogo from '@/assets/images/ProGrowth-Logo-scaled.png'
import { useAlertStore } from '@/stores/alerts'

const authStore = useAuthStore()
const clientStore = useClientStore()
const alertStore = useAlertStore()
const router = useRouter()
const { t } = useI18n()

// Local state
const showUpsellModal = ref(false)
const userData = ref<UserAccount | null>(null);
const showMenu = ref(false)
const { loggedIn, role } = storeToRefs(authStore)
const { userActiveGrowthplan } = storeToRefs(clientStore)



const onStartGrowthplan = async () => {
  const response = await clientStore.retrieveAllowedNewGrowthplans()
  if (response.data === true) router.push({ name: 'StartPage' })
  else openUpsellModal()
}

const onNavigateToGrowthplan = async () => {
  router.push({ name: 'ClientGrowthplanContainer' })
}

const onNavigateToAlltodo = async () => {
  router.push({ name: 'AllTodo' })
}

const openUpsellModal = async () => {
  try {
    const response = await clientStore.retrieveUserProfile()
    userData.value = response.data
    showUpsellModal.value = true
  } catch (error) {
    alertStore.handleError(error)
  }
}

const handleLogout = () => {
  authStore.logout();
}
</script>
